<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import router from "@/router"
import { ref, onMounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/libs/vee-validate'
import Swal from "sweetalert2";

export default {
  page: {
    title: "Register",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    ValidationProvider,
    ValidationObserver, 
  },
  data() {
    return {
      title: "Register",
      defaultNull: null,
    };
  },
setup(){
      const { $get } = useHttp()
      const { $post } = useHttp()
      const masterRef = ref(null)

      const form = ref({
      name: '',
      address:'',
      creditLimit:'',
      contactName:'',
      contactPhone:'',
      email:'',
      password:'',
      retypePassword:'',
      
      })

      const validationForm = async () => {

        if(validationDetail()){
        const validated = await masterRef.value.validate()
        if(validated){

        let dataSubmit = {
        name : form.value.name,
        address : form.value.address,
        // creditLimit : form.value.creditLimit,  
        contactName : form.value.contactName,
        contactPhone : form.value.contactPhone,
        email : form.value.email,
        password : form.value.password,
        }

      $post({
          url: 'public/register-user',
        data: dataSubmit
      })
      masterRef.value.reset();
      router.push({ name: 'public-register-success' })
      masterRef.value.reset();
       }
     }
    }

 const validationDetail = ()=> {
     if(
       form.value <1 ||
       form.value === null
      ){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "There is no details found",
          html: `You must add details`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }else{
        return true
      }
    }

    onMounted(() => {
      //getTopCust()
    })

    return {
      form,
      validationForm,
      required,
      masterRef
    }
},

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
               <validation-observer ref="masterRef">
                <b-form @submit.prevent>
                  <div class="form-group mb-3">
                        <label>Company Name</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="form.name"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                  <div class="form-group mb-3">
                        <label>Address</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="address"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="form.address"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                  <div class="form-group mb-3">
                        <label>Name</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="contact Name"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="form.contactName"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                  <div class="form-group mb-3">
                        <label>Phone</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="contact Phone"
                        rules="required"
                        >
                        <b-form-input type="number" id="" name="" value="" v-model="form.contactPhone"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                  <div class="form-group mb-3">
                        <label>Email</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="email"
                        rules="required|email"
                        >
                        <b-form-input id="" name="" value="" v-model="form.email"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider> 
                  </div>
                  <div class="form-group mb-3">
                      <label>Password</label><br />
                      <validation-provider
                          #default="{ errors }"
                          name="password"
                          rules="required|password"
                          >
                          <b-form-input type="password" id="" name="" value="" v-model="form.password"></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </div>
                  <div class="form-group mb-3">
                      <label>Retype Password</label><br />
                      <validation-provider
                          #default="{ errors }"
                          name="Retype password"
                          rules="required|confirmedBy:@password"
                          >
                          <b-form-input type="password" id="" name="" value="" v-model="form.retypePassword"></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </div>
                  <!-- <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Address"
                    label-for="customer_address"
                  >
                  <b-form-input v-model="form.address" id="" name="" value=""></b-form-input>
                  </b-form-group> -->

                  <!-- <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Credit Limit"
                    label-for="customer_creditlimit"
                  >
                    <b-form-input v-model="form.creditLimit" id="" name="" value=""></b-form-input>
                  </b-form-group> -->

                  <!-- <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Contact Name"
                    label-for="customer_contactname"
                  >
                    <b-form-input v-model="form.contactName" id="" name="" value=""></b-form-input>
                  </b-form-group> -->
                  
                  <!-- <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Contact Phone"
                    label-for="customer_contactphone"
                  >
                     <b-form-input v-model="form.contactPhone" id="" name="" value=""></b-form-input>
                  </b-form-group> -->
                  
                  <!-- <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Email Notification"
                    label-for="customer_contactphone"
                  >
                    <b-form-input v-model="form.email" id="" name="" value=""></b-form-input>
                    <span>Separated with comma (',')</span>
                  </b-form-group> -->
              </b-form>
              </validation-observer>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click.prevent="validationForm">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" :to="{ name: 'appsmd-customer-list'}">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
